<template>
  <div class="page-wrap text-center">
    <div class="session-form-hold">
      <base-card :loading="loading">
        <v-card-text>
          <img
            class="mb-4"
            height="50"
            src="@/assets/images/logo/logo-dark.svg"
            alt=""
          />
          <template v-if="!success">
            <h4>Gostaria de aceitar a cortesia?</h4>
            <v-alert
              v-if="error"
              border="left"
              class="text-left"
              text
              type="error"
            >
              {{ error }}
            </v-alert>
            <v-row v-if="!error">
              <v-col cols="6">
                <v-btn
                  color="error"
                  large
                  block
                  @click="deny"
                  :disabled="loading"
                  :loading="loading && !accepted"
                >
                  Não
                </v-btn>
              </v-col>
              <v-col cols="6">
                <v-btn
                  color="success"
                  large
                  block
                  @click="accept"
                  :disabled="loading"
                  :loading="loading && accepted"
                >
                  Sim
                </v-btn>
              </v-col>
            </v-row>
            <v-btn v-else color="secondary" block :to="{ name: 'app.ticket' }">
              Voltar para o app
            </v-btn>
          </template>
          <template v-else>
            <v-alert border="left" class="text-left" text type="success">
              Cortesia {{ accepted ? "resgatada" : "recusada" }} com sucesso!
            </v-alert>
            <v-btn
              v-if="accepted"
              color="primary"
              block
              :to="{ name: 'app.ticket', params: { id: ticket.id } }"
            >
              Ver ingresso
            </v-btn>
            <v-btn
              v-if="!accepted"
              color="secondary"
              block
              :to="{ name: 'app.ticket' }"
            >
              Voltar para o app
            </v-btn>
          </template>
        </v-card-text>
      </base-card>
    </div>
  </div>
</template>

<script>
import { courtesy } from "@/services/api";

export default {
  data: () => ({
    success: false,
    loading: false,
    error: false,
    accepted: null,
    ticket: null,
  }),
  methods: {
    accept() {
      this.accepted = true;
      this.run(true);
    },
    deny() {
      this.accepted = false;
      this.run(false);
    },

    async run(accept) {
      try {
        this.loading = true;
        this.error = false;
        const id = this.$route.query.id;
        const response = await courtesy(id, accept);
        
        this.ticket = response.ticket;
        this.success = true;
      } catch (e) {
        this.error =
          e.message ||
          `Não foi possível ${this.accept ? "aceitar" : "recusar"} a cortesia`;
      } finally {
        this.loading = false;
      }
    },
  },
  mounted() {},
};
</script>
<style lang="scss" scoped>
.page-wrap {
  background-color: #B01C3F !important;
  display: flex;
  align-items: center;
  padding: 40px 1rem;
  height: 100%;
  min-height: 100vh;
}
.session-form-hold {
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
}
</style>
